.about {
  ul {
      list-style-type:none;
  }

  li {
      padding: 10px;
      display:inline;
      float:left;
  }

  p.title {
    line-height: 10px;
    color: #404040;
    font-family: $font-family-app-store;
    font-size: 14px;
    font-weight: 300;
    word-wrap: break-word;
  }

  p.kind {
    color: #B0B0B5;
    font-size: 13px;
    font-weight: 300;
    line-height: 6px;
  }

  div.row {
    float: left;
    width: 100%;
    padding-top: 50px;
  }
}
