// Mix-ins
%padding-small {
  padding: 2% 20%;
  @media (max-width: 1000px) {
    padding: $padding-x-small $padding-x-small;
  }
}
%padding-regular {
  padding: $padding-small $padding-large;
  @media (max-width: 1000px) {
    padding: $padding-small * 1.5 $padding-large / 1.6;
  }
}
%padding-header {
  padding: 2% 20% 2% 30%;
  @media (max-width: 1000px) {
    padding: 3% 20%;
  }
}

%link-hover {
  text-decoration: underline;
  color: darken($link-color, 15%);
}

// Buttons
.button {
  color: $button-color;
  border-radius: 0.2em;
  border: 1px solid;
  display: inline-block;
  margin: 1em 0;
  padding: 0.3em 0.60em;
}
a.button:hover {
  background: $button-color;
  border: 1px solid $button-color;
  color: $background-color;
  text-decoration: none;
}
// States
.disabled {
  opacity: 0.7;
}
