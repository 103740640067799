// Typography
$font-family-main: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-family-headings: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$font-family-app-store: Helvetica, sans-serif;
$font-size: 1.25em;

// Padding
$padding-large: 30%;
$padding-small: 3%;
$padding-x-small: 3%;

// Brand colours
$brand-color: #f7f7f7;
$background-color: #f7f7f7;
$border-color: rgba(0, 0, 0, 0.1); // rgba recommended if using feature images

// Typography colours
$text-color: #383838;
$link-color: #CD5C5C;
$selection-color: #D4D4D4; // visible when highlighting text

$button-color: #388E8E;

// Header colours
$header-link-color: #383838;

// Feature image for articles
$feature-image-text-color: #fff;
$feature-image-size: cover; // options include "cover", "contain", "auto"

// Header description box
$header-desc-background-color: #CD5C5C;
$header-desc-text-color: #f7f7f7;
